@import "src/common/assets/styles/mixin";

.header-mobile {
  width: 100%;
  background-color: var(--app-color-white);
  transition: 0.2s all linear;

  &--index-variant {
    //background-color: white;
    //background: transparent;
    //background: linear-gradient(0deg, rgba(30, 75, 152, 0.5), rgba(30, 75, 152, 0.5)), #253858;
    background: #213353;
    //margin-bottom: 55px;


    a {
      color: var(--app-color-white);
    }

    .header-mobile__logo {
      path {
        fill: white;
      }
    }
  }

  &__logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 12px;

    > a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      svg {
        margin-bottom: 10px;
      }
    }
  }

  &__logo-description {
    font-size: 13px;
    white-space: nowrap;
    color: #F4F2F6;
    opacity: 0.6;
  }

  &__logo-container-text {
    color: white;
    display: flex;
    font-weight: 300;
    font-size: 27px;
    line-height: 27px;
    margin-top: 20px;
  }

  .header-mobile__wrapper {
    //padding: 0 24px;
    display: grid;
    //height: 60px;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }

  .header-mobile__logo {
    width: 85px;
  }

  .header-mobile__burger {
    //justify-self: flex-end;
  }

  .header-mobile__search-button {
    //margin: 0 auto;
    padding: 24px;
    width: 100%;

    button {
      min-width: auto;
      width: 100%;
    }

    .button-search {
      border-radius: 8px;
      grid-gap: 10px;
      border: 1px solid #E6E6E8;

      svg {
        margin-bottom: 0;
      }
    }
  }

  .header-mobile__exist-button {
    display: grid;
    grid-template-columns: 24px auto;
    grid-gap: 16px;
    align-items: center;

    .button-search--outline {
      background-color: white;
    }
  }

  .button-search {
    height: 50px;
    background: #ffffff;
    border: none;
  }

  &--index-variant {
    .button-search {
      height: 62px;
    }
  }

}

.search-mobile-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#2F2C37, .33);


  .search-mobile-form__close-background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 20;
  }

  .search-mobile-form__inner {
    padding: 24px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 30;
    background-color: white;
    border-bottom-left-radius: var(--app-card-radius);
    border-bottom-right-radius: var(--app-card-radius);
  }

  .search-mobile-form__header {
    margin-bottom: 23px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 34px;
    }

    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #2F2C37;
    }
  }

  .search-mobile-form__buttons {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 8px;

    .search-mobile-form__button {
      &:first-child {
        grid-column: 1/3;
      }
    }
  }


}



