@import "../../../../../../../assets/styles/mixin";

$datePickerBorderColor: #C5C6CF;
$datePickerBorderOpacity: 0.5;

%input-style {
  color: #E05233;
  border: none;
  margin-bottom: 0;
  height: auto;
  font-weight: 500;
  font-size: 15px;
  padding: 0 10px 14px 0;

  &:disabled {
    background: #fff;
    opacity: 1;
  }
}


%label-style {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #8C8896;
  display: block;
}

.hotel-rooms-custom-date-pickers {
  @include mobileOnly() {
    $datePickerBorderColor: #F4F2F6;
    $datePickerBorderOpacity: 1;
  }

  display: grid;
  grid-template-columns: 1fr  1fr;
  align-items: center;
  border-bottom: 1px solid rgba($datePickerBorderColor, $datePickerBorderOpacity);
  margin-bottom: 20px;

  .hotel-rooms-custom-date-pickers__item {
    margin-bottom: 15px;
    display: flex;

    &:first-child {
      > div {
        width: 100%;
        border-right: 1px solid rgba($datePickerBorderColor, $datePickerBorderOpacity);
      }
    }

    &:last-child {
      padding-left: 27px;
    }
  }

  .hotel-rooms-custom-date-pickers__label {
    @extend %label-style;
    margin-bottom: 5px;
  }

  .hotel-rooms-custom-date-pickers__input {
    input {
      padding: 0;
      cursor: pointer;
      @extend %input-style;
      font-size: 15px;
      font-weight: 400;

    }
  }
}

.hotel-rooms-search-mobile-form {
  .hotel-rooms-search-mobile-form__label {
    @extend %label-style;
  }

  .hotel-rooms-search-mobile-form__field {
    border-bottom: 1px solid rgba($datePickerBorderColor, $datePickerBorderOpacity);
    margin-bottom: 20px;
    margin-top: 7px;
    @extend %input-style;
    font-weight: 400;
  }
}


