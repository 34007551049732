.last-trip-item {
   position: relative;

   .last-trip-item__wrapper {
      display: grid;
      grid-template-columns: 1fr 3fr;
      border: 1px solid #d1cfd5;
      border-radius: 8px;
      padding: 10px;
   }

   &__image {
      box-shadow: 0 6px 16px rgba(140, 136, 150, 0.08);
      border-radius: 4px;
      min-width: 95px;
   }
   &__block-information {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }

   &__block-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   &__date {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: #8c8896;
   }
   &__setting {
   }
   &__name {
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      color: #2f2c37;
   }
   &__price {
      font-weight: 400;
      font-size: 17px;
      line-height: 23px;
      color: #e05233;
   }
}

.order-item-menu {
   position: relative;
   z-index: 10;
   background-color: white;
   min-width: 233px;
   padding: 20px;
   box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.18);
   border-radius: var(--app-card-radius);

   ul {
      list-style: none;
      li {
         cursor: pointer;
         margin-bottom: 20px;
         font-weight: 500;
         font-size: 13px;
         line-height: 19px;
         color: #2f2c37;
         &:last-child {
            margin-bottom: 0;
         }
      }
   }
}

//.last-trip-item__button {
//   position: absolute;
//   right: 10px;
//   top: 10px;
//   display: flex;
//   align-items: flex-end;
//   flex-direction: column;
//
//   button {
//      cursor: pointer;
//      text-align: right;
//      background-color: white;
//      position: relative;
//
//      &:hover {
//         svg {
//            .icon-dots-background {
//               fill: #f4f2f6;
//            }
//         }
//      }
//   }
//}
