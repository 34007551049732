.search-mobile-modal {
  display: flex;
  flex-direction: column;

  .modal-mobile__body {
    display: flex;
    flex-direction: column;
    //height: 100%;
    min-height: 500px;
    max-height: 55vh;
    padding-bottom: 0;
  }

  .search-mobile-modal__header {
    padding-top: var(--app-blocks-padding);
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: #2f2c37;
    display: grid;
    align-items: center;
    grid-template-columns: 25px auto 25px;
    margin-bottom: 24px;
    width: 100%;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 21px;
    line-height: 31px;
    color: #2f2c37;
  }

  &__select {
    flex: 1;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
  }
}

.search-mobile-modal-select {
  display: flex;
  flex-direction: column;
  flex: 1;

  .search-mobile-modal-select__control {
    flex: 0 0 64px;
  }

  .search-mobile-modal-select__menu {
    position: static;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    //margin: 0;
    //overflow: hidden;
    margin: 0 -24px;
    width: 100vw;

    flex: 1;
    padding-top: 8px;

    .search-mobile-modal-select__menu-list {
      flex: 1;
      padding-top: 11px;
    }
  }

  .search-mobile-modal-select__option {
    padding: 0 24px;
  }

  .search-mobile-modal-select__option--is-selected {
    background: #f4f2f6;

    .react-select-option {
      border-bottom: none;
    }
  }

  .search-mobile-modal-select__control {
    height: 64px;
    border-radius: var(--app-card-radius);
    border-color: #6d5ecb;
  }

  .react-select-option {
    padding: 15px 0;
  }
}
