

.mui-form-right-component{
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}

.mui-form-group {
  position: relative;
  //padding-top: 1.3rem;

  .mui-select-icon{
    position: absolute;
    right: 10px;
    top:calc(50% - 4px);
    pointer-events: none;

    path{
    fill: var(--app-input-label-color);
      opacity: .7;

    }
  }

  input[type=hidden] {
    opacity: 0;

    ~ .bar{
      display: none;
    }
  }

  select {
    width: 100%;
    font-size: 1rem;
    height: 2rem;
    padding: 0.125rem 0.125rem 0.0625rem;
    background: none;
    border: none;
    line-height: 1.6;
    box-shadow: none;
  }

  .control-label {
    position: absolute;
    top: 0.25rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    color: var(--app-input-label-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
  }

  .bar {
    position: relative;
    border-bottom: 0.0625rem solid #999;
    display: block;

    &:before {
      content: '';
      height: 0.125rem;
      width: 0;
      left: 50%;
      bottom: -0.0625rem;
      position: absolute;
      background-color: var(--app-input-focus-color);
      -webkit-transition: left 0.28s ease, width 0.28s ease;
      transition: left 0.28s ease, width 0.28s ease;
      z-index: 2;
    }
  }

  input, textarea {
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    //color: transparent;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    box-shadow: none;
    text-overflow: ellipsis;
  }

  input:disabled {
    cursor: not-allowed;
  }

  select ~ .control-label,
  input:valid ~ .control-label,
  input:disabled ~ .control-label,
  input.form-file ~ .control-label,
  input.has-value ~ .control-label,
  textarea:focus ~ .control-label,
  textarea:valid ~ .control-label,
  textarea.form-file ~ .control-label,
  textarea.has-value ~ .control-label {
    font-size: 0.8rem;
    color: var(--app-input-label-color);
    top: -1.3rem;
    left: 0;
  }

  input:focus ~ .control-label,
  select:focus ~ .control-label{
    color: var(--app-input-focus-color);
  }

  select ~ .control-label{
    font-size: 0.8rem;
    top: -1.3rem;
    left: 0;
    color: var(--app-input-label-color);
  }

  input {
    height: 2rem;
  }

  textarea {
    resize: none;
  }

  select, input, textarea {
    outline: none;

    &:focus {
      ~ .bar {
        &:before {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  &.isError {
    .control-label {
      color: var(--app-input-error-color)  !important;
    }


    .bar {
      &:before {
        width: 100%;
        left: 0;
        background-color: var(--app-input-error-color);
      }
    }

  }
}


// checkbox and radiobutton
.checkbox label,
.form-radio label {
  position: relative;
  cursor: pointer;
  padding-left: 2rem;
  text-align: left;
  color: #333;
  display: block;
}
.checkbox input,
.form-radio input {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
}

.radio {
  margin-bottom: .5rem;
}
.radio .helper {
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #999;
}
.radio .helper::before, .radio .helper::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  -webkit-transition: -webkit-transform 0.28s ease;
  transition: -webkit-transform 0.28s ease;
  transition: transform 0.28s ease;
  transition: transform 0.28s ease, -webkit-transform 0.28s ease;
  border-radius: 50%;
  border: 0.125rem solid currentColor;
}
.radio .helper::after {
  -webkit-transform: scale(0);
  transform: scale(0);
  background-color: var(--app-input-focus-color);
  border-color: var(--app-input-focus-color);
}
.radio label:hover .helper {
  color: var(--app-input-focus-color);
}
.radio input:checked ~ .helper::after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
.radio input:checked ~ .helper::before {
  color: var(--app-input-focus-color);
}

.checkbox {
  //margin-top: 3rem;
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
}
.checkbox .helper {
  color: #999;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.2rem;
  height: 1.2rem;
  z-index: 0;
  border: 0.125rem solid currentColor;
  border-radius: 0.0625rem;
  -webkit-transition: border-color 0.28s ease;
  transition: border-color 0.28s ease;
}
.checkbox .helper::before, .checkbox .helper::after {
  position: absolute;
  height: 0;
  width: 0.2rem;
  background-color: var(--app-input-focus-color);
  display: block;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 0.25rem;
  content: '';
  -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
  transition: opacity 0.28s ease, height 0s linear 0.28s;
  opacity: 0;
}
.checkbox .helper::before {
  top: 0.75rem;
  left: 0.48rem;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  box-shadow: 0 0 0 0.0625rem var(--app-color-white);
}
.checkbox .helper::after {
  top: 0.4rem;
  left: 0.1rem;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.checkbox label:hover .helper {
  color: var(--app-input-focus-color);
}
.checkbox input:checked ~ .helper {
  color: var(--app-input-focus-color);
}
.checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
  opacity: 1;
  -webkit-transition: height 0.28s ease;
  transition: height 0.28s ease;
}
.checkbox input:checked ~ .helper::after {
  height: 0.5rem;
}
.checkbox input:checked ~ .helper::before {
  height: 1.3rem;
  -webkit-transition-delay: 0.28s;
  transition-delay: 0.28s;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 1rem;
}
