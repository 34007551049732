.hotel-callback-block {
  background: linear-gradient(60.82deg, rgba(239, 250, 247, 1.00) 0%, rgba(216, 243, 235, 1.00) 100%);
  padding: 16px 24px;



  &__close{
    width: 56px;
    min-width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -20px;
    margin-top: -20px;
    margin-bottom: -20px;
    cursor: pointer;

    svg{
      width: 14px;
      height: 14px;
      path{
        fill: rgba(109, 113, 136, 1);
      }
    }
  }

  &__header{
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;

    svg{
      path{
        fill: rgba(109, 113, 136, 1);
      }
    }

    button{
      appearance: none;
      border: none;
      outline: none;
      background-color: rgba(0,0,0,0);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__animation {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }


  &__description {
    color: #8c8896;
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 9px;
  }

  &__phone {
    color: #3d3a45;
    text-align: left;
    font-weight: 600;
    font-size: 13px;
  }


  h4 {
    color: #0c271f;
    font: 600 24px/31px 'Montserrat', sans-serif;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 10px;

    @media (max-width: 767px) {
      font-size: 21px;
      font-weight: 500;
    }
  }

  h6 {
    color: #194d3d;
    text-align: left;
    font: 500 13px/19px 'Montserrat', sans-serif;
    margin-bottom: 24px;
    margin-top: 16px;
    
    @media (max-width: 767px) {
      margin-bottom: 14px;
    }
  }

  
  

  button {
    width: 100%;
    height: 49px;
  }

  .field-control {
    margin-bottom: 24px;

    @media (max-width: 767px) {

    margin-bottom: 16px;
    }

    &.isError {

      .mui-form-group {
        input {
          border: 2px solid var(--app-input-error-color) !important;

        }
      }
    }

    .mui-form-group {
      input {
        height: 50px;
        border: 2px solid rgba(255, 255, 255, 1);
        padding: 21px 16px 6px;
        background-color: white;
        border-radius: 8px;

        &:focus {
          border: 2px solid #5ecbaa;

          + .control-label {
            color: #999ca0;
            //padding-left: 0;
            top: 0.25rem !important;
            font-size: 11px !important;
          }
        }

      }

      .bar {
        display: none;
      }
    }

    .control-label {
      padding-left: 1rem;
      color: #999ca0;
      top: 0.25rem !important;
      font-size: 11px !important;
    }
  }
}