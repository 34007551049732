@keyframes mapListItemaAnimationIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.map-list-item {
  display: grid;
  grid-template-columns: 300px 1fr;
  border-bottom: 1px solid #EBEBEB;
  overflow: hidden;
  padding-bottom: 20px;
  margin-bottom: 25px;
  animation: mapListItemaAnimationIn 0.3s forwards;
  opacity: 0;
  animation-delay: 0.4s;

  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &__name {
    margin-top: 3px;

    &:hover {
      color: var(--app-color-primary);
    }
  }

  &__carousel {
    overflow: hidden;
    width: 100%;

    .carousel .slick-slider .slick-dots, .carousel.carousel--secondary .slick-slider .slick-dots {
      bottom: 10px;
    }
  }

  &__block-right {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
  }

  &__block-name-favorite {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;

    @media all and (max-width: 1300px) {
      margin-bottom: 5px;
    }
  }

  &__location {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: var(--color-text-gray);
  }

  &__name {
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
    color: var(--color-text-black);
  }

  &__properties {
    margin-bottom: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
  }

  &__block-footer {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    //align-items: flex-start;
    margin-top: auto;
    padding-bottom: 3px;
  }

  &__star {
    //align-self: flex-end;
    flex: 1;
  }

  &__block-price {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    align-items: flex-start;
  }

  &__block-price-container {
    text-align: right;
  }

  &__price {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #E05233;


    &.price-viewer--old-price {
      color: #8C8896;
      text-decoration: none;

      .price-viewer__price, .price-viewer__additional-text {
        text-decoration: line-through;
        color: #8C8896;
        font-size: 13px;
        line-height: 21px;
        font-weight: 400;
      }
    }

    .price-viewer__additional-text {
      font-size: 13px;
      color: #000;
      font-weight: 400;
    }
  }

  &__meals {
    text-align: right;
  }

  &__button-submit {
    margin-top: 6px;
    margin-left: 20px;
  }
}
