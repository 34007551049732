:root {
  --app-color-black: #000000;
  --app-color-white: #ffffff;
  //--app-color-primary: #6D5ECB;
  --app-color-primary: #EB5757;
  --app-color-disabled: #D1CFD5; //#97A0AF;
  --app-color-transparent: rgba(0, 0, 0, 0);

  --color-gray: #6D7188;
  --app-color-primary-opacity33: rgba(235, 87, 87, 0.33);


  --color-primary-green: #5ECBAA;
  --color-primary-blue: #6D5ECB;
  --color-primary-red: #E05233;
  --color-primary-red-light: #E8856F;
  --color-primary-50: #FCE8E8;

  --color-text-black: #2F2C37;
  --color-text-dark: #3D3A45;
  --color-text-gray: #8C8896;
  --color-icon-light-gray: #C5C6CF;
  --color-light-gray: #D1CFD5;
  --color-light-gray-033: rgba(197, 198, 207, 0.33);
  --color-icon-gray: #6D7188;

  // form colors
  --app-input-error-color: #E05233;
  --app-input-focus-color: #6D5ECB;
  --app-input-label-color: var(--color-text-gray);
  --app-input-text-color: #24253D;

  // buttons
  --app-form-height-small: 42px;
  --app-form-height-middle: 48px;
  --app-form-height-large: 69px;

  --app-form-height-big: 55px;


  --app-button-border-outline: 1px solid #5ECBAA;
  --app-button-color-outline: #5ECBAA;
  --app-button-border-fillable: 1px solid #5ECBAA;
  --app-button-background-fillable: #5ECBAA;


  --app-button-background-fillable-disabled: var(--app-color-disabled);
  --app-button-background-outline-disabled: var(--app-background-transparent);
  --app-button-border-outline-disabled: 1px solid var(--app-color-disabled);
  --app-button-border-fillable-disabled: 1px solid var(--app-color-disabled);
  --app-button-color-outline-disabled: var(--app-color-disabled);
  --app-button-color-fillable-disabled: var(--app-color-white);
  --app-button-border-radius: 4px;

  --app-color-global-ajax-loader: var(--app-color-G400);

  --app-index-header: 500;
  --app-index-sidebar: 300;
  --app-index-menu: 200;
  --app-index-modal-menu: 400;
  --app-index-modal: 502;
  --app-index-toolbar-mobile: 450;

  --app-header-height: 73px;

  --color-image-background: #F4F2F6;
  --color-content-background: #fff;
  --color-content-alternative-background: #F8F8F8;

  --common-borders-color: #F4F2F6;
  //--mobile-footer-height: 54px;
  --mobile-footer-height: calc(54px + env(safe-area-inset-bottom));

  // cards
  --app-card-radius: 8px;
  --app-blocks-padding: 24px;

  --footer-caption-texts-color: #3D3A45;
  --footer-texts-color: #3D3A45;
  --footer-background: #f7f7f7;
  //--footer-divider-color: #D1CFD5;
  --footer-divider-color: #D1CFD5; //rgba(255, 255, 255, 0.17);
  --footer-site-logo-stroke: #8C8896;
}
