%input-style {
  color: #E05233;
  border: none;
  border-bottom: 1px solid rgba(#C5C6CF, .5);
  margin-bottom: 20px;
  height: auto;
  //height: 40px;
  font-weight: 400;
  //margin-top: -10px;
  padding: 0 10px 14px 0;
}


%label-style {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #8C8896;
  display: block;
}

.hotel-rooms-search-title {
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  color: #2F2C37;
  margin-bottom: 19px;
}

@keyframes searchFormFocusBackgroundIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hotel-rooms-search {
  padding: 22px 26px 26px 26px;
  border: 1px solid rgba(#C5C6CF, .5);
  border-radius: var(--app-card-radius);

  .hotel-rooms-search__date-pickers {
    //display: grid;
  }

  .hotel-rooms-search__button {
    button {
      width: 100%;
    }
  }

  .hotel-rooms-search__people {

    .hotel-people-dropdown {
      width: 100%;
      margin-top: 0;
    }

    .hotel-rooms-search__people-label {
      @extend %label-style;
    }

    .field-input {
      > input {
        @extend %input-style;
        font-size: 15px;
      }
    }
  }

  &__focus-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.33);
    opacity: 0;
    animation: searchFormFocusBackgroundIn 0.7s forwards;
    animation-delay: 0.3s;
  }

  &--focused {
    position: relative;
    background-color: #fff;
    z-index: 501;
  }
}


